import { FC } from 'react'
import clsx from 'clsx'
import SafeLink from '@ignition/library/components/atoms/safe-link'
import { PageButtonProps } from '@ignition/library/components/molecules/pagination'

const styles = {
  a: (active: boolean, disabled: boolean) =>
    clsx(
      active
        ? 'border-primary text-primary'
        : clsx('border-transparent text-gray-500 hover:border-gray-300', !disabled && 'hover:text-gray-700'),
      disabled ? 'opacity-10' : 'hover:cursor-pointer border-t-2',
      'inline-flex items-center pt-4 text-sm font-medium',
    ),
}

interface BasePaginationButtonProps extends PageButtonProps {
  disabled?: boolean
}

export const BasePaginationButton: FC<BasePaginationButtonProps> = ({
  children,
  active,
  className,
  disabled = false,
  href,
}) => {
  return (
    <SafeLink aria-current="page" href={href} scroll={false}>
      <span className={clsx(styles.a(!!active, disabled), className)}>{children}</span>
    </SafeLink>
  )
}
